html, * {
    padding: 0;
    margin: 0;
}

html, body {
    background-color: #522465 !important;
     background: radial-gradient(#522465, #591BC5);
     background-size: 600% 600%;
     animation: Gradient 15s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body 

p {
    color: #ff8eae;
}

h1, h2, h3, h4, h5 {
    color: #01C08F !important;
    text-shadow: 0px 0px 10px #286b88;

}

h1, h2, h3, h4, h5, p, .filter li {
    position: relative;
    z-index: 1;
}

h2 {
    line-height: 1.1 !important;
}

h3 {
    font-size: 1.35rem !important;
}

canvas {
    z-index: 10;
}

.game {
    &__viewer {
        position: relative;
        min-height: 400px;
        min-height: 300px;
        max-width: 430px;
        margin: 0 auto;

        &:hover, &.is-seen {
            .game__preview--2d { 
                // display: none; 
                opacity: 0;
            }

            .game__preview--3d {
                opacity: 1;
                transition-delay: 0.1s;
            }

            .game__preview canvas {
                display: block;
            }
        }
    }

    &__preview {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        canvas, img {
            position: relative;
            z-index: 100000;
        }

        &--2d {
            user-select: none;
            transition: opacity 0.5s;
            text-align: center;
            opacity: 1;
        }

       &--3d {
           transition: opacity 0.5s;
           transition-delay: 0.1s;
           opacity: 0;
           cursor: grab;
       }

        [data-diagram] {
            width: 400px;
            height: 400px;
            display: block;
            max-width: 100%;
            max-height: 320px;
            margin: 0 auto;
        }

        canvas { 
            display: none; 
            max-width: 100%;
        }

        &:hover canvas { 
            display: block;
        }
    }

    &__details {
        user-select: none;
        margin-bottom: 60px;
        text-align: center;
        
        @media (min-width: 768px) {
            // text-align: left;
            margin-bottom: 80px;
        }
    }
}

.box-art {
    &__fallback {
        max-width: 100%;
        height: auto;

        max-height: 100%;
        width: auto;
        margin: 0 auto;
        text-align: center;
    }
}

.filter {
    li {
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
        color: white;

        &.is-active {
            text-decoration: underline;
        }
    }
}
canvas {
    width: 100%;
    height: 100%;
    display: block;
    max-width: 100%;
}


/* REGULAR SCANLINES SETTINGS */

// width of 1 scanline (min.: 1px)
$scan-width: 2px;
$scan-crt: true;
$scan-fps: 120;
$scan-color: rgba(#000, .35);

// set z-index on 8, like in ♥ 8-bits ♥, or…
// set z-index on 2147483648 or more to enable scanlines on Chrome fullscreen (doesn't work in Firefox or IE);
$scan-z-index: 1000;

$scan-moving-line: true;
$scan-opacity: .55;

/* MIXINS */

// apply CRT animation: @include scan-crt($scan-crt);
@mixin scan-crt($scan-crt) {
    @if $scan-crt==true {
        animation: scanlines 6s steps($scan-fps) infinite;
    }

    @else {
        animation: none;
    }
}

// apply CRT animation: @include scan-crt($scan-crt);
@mixin scan-moving($scan-moving-line) {
    @if $scan-moving-line==true {
        animation: scanline 6s linear infinite;
    }

    @else {
        animation: none;
    }
}

/* CSS .scanlines CLASS */

.scanlines {
    position: relative;
    overflow: hidden; // only to animate the unique scanline
    min-height: 100vh;

    &:before,
    &:after {
        display: block;
        pointer-events: none;
        content: '';
        position: absolute;
    }

    // unique scanline travelling on the screen
    &:before {
        // position: absolute;
        // bottom: 100%;
        width: 100%;
        height: $scan-width * 1;
        z-index: $scan-z-index + 1;
        background: $scan-color;
        opacity: $scan-opacity;

        @media (min-width: 768px) {
            @include scan-moving($scan-moving-line);
        }
    }

    // the scanlines, so!
    &:after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $scan-z-index;
        background: linear-gradient(to bottom,
                transparent 50%,
                $scan-color 51%);
        background-size: 100% $scan-width*2;

        @media (min-width: 768px) {
            @include scan-crt($scan-crt);
        }
    }
}

/* ANIMATE UNIQUE SCANLINE */
@keyframes scanline {
    0% {
        transform: translate3d(0, 200000%, 0);
    }
}

@keyframes scanlines {
    0% {
        background-position: 0 50%;
    }
}